import { css, Global } from '@emotion/react'
import { StyledEngineProvider } from '@mui/material'
import { Fragment, memo, useEffect, useLayoutEffect, useMemo } from 'react'
import useMedia from '~/hooks/useMedia'
import UserAgentWarning from '~/modules/AppLayout/UserAgentWarning'
import { createGridArea } from '~/modules/SDK/grid/createGridArea'
import { useSignalrInit2 } from '~/modules/SDK/Signalr/useSignalrInit2'
import { GlobalParentsHeight100 } from '~/pages/heineken_template/_private/GlobalParentsHeight100'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { useIsomorphicLayoutEffect } from 'react-use'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'

const grid = createGridArea('grid')
const chart = createGridArea('chart')
const row1 = createGridArea('row1')
const row2 = createGridArea('row2')
const row3 = createGridArea('row3')
const row4 = createGridArea('row4')
const row5 = createGridArea('row5')
const row6 = createGridArea('row6')
const col1 = createGridArea('col1')
const col2 = createGridArea('col2')
const col3 = createGridArea('col3')
const col4 = createGridArea('col4')
const col5 = createGridArea('col5')
const col6 = createGridArea('col6')
const drawer1 = createGridArea('drawer1')
const drawer2 = createGridArea('drawer2')

const drawerCSS = css`
  width: 336px;
  height: 100%;
`

const layoutDefaultsCSS = css`
  width: 100%;
  height: 100%;
  place-content: center;
  grid-auto-rows: max-content;
`

export const TemplatePage = memo<
  ReactProps<{
    templateProps: TemplateProps
  }>
>(function TemplatePage(props) {
  const layouts = props.templateProps.useLayout(props.templateProps.layout)
  const { isPc } = useMedia()

  useSignalrInit2()

  const templateProps = props.templateProps
  const canAccess = templateProps.permissions.pageview.useCheck()
  const hooks = templateProps.hooks

  /**
   * UseIsomorphicLayoutEffect 它等於 useLayoutEffect，但它可以運作在 SSR 之中。
   *
   * 在這裡我們明確需要在 DOM 被 render 之前，作一些設定。例如在 NextPage 換頁時，且在頁面 create charting 之前，要將
   * store.widgetOptions 細節作一些調整，我們明確需要在 useMount 的更前段做這件事情。同時使 onPageEnter / onPageLeave
   * 更符合命名，即代表「當頁面進入時，的最早的那時機點」。
   *
   * 當 NextPage 換頁時，執行順序：
   *
   * - Template.useIsomorphicLayoutEffect() -> callback() // 它會是最早的時機點
   * - Template.<Charting /> -> useMount() -> charting.create()
   * - Template.UseEffect() -> callback()
   */
  useIsomorphicLayoutEffect(() => {
    debugAPI.template.log(`templateProps.onPageEnter?.()`)
    templateProps.onPageEnter?.()

    return () => {
      debugAPI.template.log(`templateProps.onPageLeave?.()`)
      templateProps.onPageLeave?.()
    }
  }, [])

  templateProps.useGlobalHooks()

  const LoginLayout_FullPage = useElementAsComponent(layouts.login)
  const IndexLayout_Charting = useElementAsComponent(layouts.Charting)
  const IndexLayout_Col1 = useElementAsComponent(layouts.Col1)
  const IndexLayout_Col2 = useElementAsComponent(layouts.Col2)
  const IndexLayout_Col3 = useElementAsComponent(layouts.Col3)
  const IndexLayout_Col4 = useElementAsComponent(layouts.Col4)
  const IndexLayout_Col5 = useElementAsComponent(layouts.Col5)
  const IndexLayout_Col6 = useElementAsComponent(layouts.Col6)
  const IndexLayout_Row1 = useElementAsComponent(layouts.Row1)
  const IndexLayout_Row2 = useElementAsComponent(layouts.Row2)
  const IndexLayout_Row3 = useElementAsComponent(layouts.Row3)
  const IndexLayout_Row4 = useElementAsComponent(layouts.Row4)
  const IndexLayout_Row5 = useElementAsComponent(layouts.Row5)
  const IndexLayout_Row6 = useElementAsComponent(layouts.Row6)
  const IndexLayout_Drawer1 = useElementAsComponent(layouts.Drawer1)
  const IndexLayout_Drawer2 = useElementAsComponent(layouts.Drawer2)
  const IndexLayout_Provider = layouts.Providers

  if (!canAccess && props.templateProps.layout.login) {
    return (
      <hooks.Provider>
        <LoginLayout_FullPage />
        <UserAgentWarning />
      </hooks.Provider>
    )
  }

  return (
    <StyledEngineProvider injectFirst>
      <hooks.Provider>
        <IndexLayout_Provider>
          <GlobalParentsHeight100 />
          <Global styles={templateProps.globalCssset} />
          <TemplateTheme.Display>
            <grid.Grid
              css={css`
                ${layoutDefaultsCSS};
                ${layouts.cssset};
              `}
            >
              <store.drawerLeft.Drawer>
                <div
                  className={drawer1.name}
                  css={drawerCSS}
                >
                  {!isPc && layouts.Drawer1 && <IndexLayout_Drawer1 />}
                </div>
              </store.drawerLeft.Drawer>
              <store.drawerRight.Drawer>
                <div
                  className={drawer2.name}
                  css={drawerCSS}
                >
                  {!isPc && layouts.Drawer2 && <IndexLayout_Drawer2 />}
                </div>
              </store.drawerRight.Drawer>
              {isPc && layouts.Drawer1 && (
                <drawer1.Area>
                  <IndexLayout_Drawer1 />
                </drawer1.Area>
              )}
              {isPc && layouts.Drawer2 && (
                <drawer2.Area>
                  <IndexLayout_Drawer2 />
                </drawer2.Area>
              )}
              {layouts.Row1 && (
                <row1.Area>
                  <IndexLayout_Row1 />
                </row1.Area>
              )}
              {layouts.Row2 && (
                <row2.Area>
                  <IndexLayout_Row2 />
                </row2.Area>
              )}
              {layouts.Row3 && (
                <row3.Area>
                  <IndexLayout_Row3 />
                </row3.Area>
              )}
              {layouts.Row4 && (
                <row4.Area>
                  <IndexLayout_Row4 />
                </row4.Area>
              )}
              {layouts.Row5 && (
                <row5.Area>
                  <IndexLayout_Row5 />
                </row5.Area>
              )}
              {layouts.Row6 && (
                <row6.Area>
                  <IndexLayout_Row6 />
                </row6.Area>
              )}
              {layouts.Col1 && (
                <col1.Area>
                  <IndexLayout_Col1 />
                </col1.Area>
              )}
              {layouts.Col2 && (
                <col2.Area>
                  <IndexLayout_Col2 />
                </col2.Area>
              )}
              {layouts.Col3 && (
                <col3.Area>
                  <IndexLayout_Col3 />
                </col3.Area>
              )}
              {layouts.Col4 && (
                <col4.Area>
                  <IndexLayout_Col4 />
                </col4.Area>
              )}
              {layouts.Col5 && (
                <col5.Area>
                  <IndexLayout_Col5 />
                </col5.Area>
              )}
              {layouts.Col6 && (
                <col6.Area>
                  <IndexLayout_Col6 />
                </col6.Area>
              )}
              <chart.Area>{layouts.Charting && <IndexLayout_Charting />}</chart.Area>
            </grid.Grid>

            <UserAgentWarning />
            <ToastContainer
              position='bottom-center'
              theme={useThemeStore.getState().theme}
              css={css`
                .Toastify__toast.Toastify__toast-theme--dark {
                  border: 1px solid #fff;
                }
              `}
            />
          </TemplateTheme.Display>
        </IndexLayout_Provider>
      </hooks.Provider>
    </StyledEngineProvider>
  )
})
